import $ from 'jquery';
window.$ = $;
window.jQuery = $;

import App from '@sepamat44/6tm-components';
window.App = App;

import 'jquery-ajax-unobtrusive';
import 'jquery-validation';
import 'jquery-validation-unobtrusive';

import toastr from 'toastr';
window.toastr = toastr;


require('bootstrap');

const Modal = require('./js/utils/Modal');
window.Modal = new Modal();

// Components
import unimplemented from './js/components/unimplemented';

// Pages
App.Kernel.registerComponent('unimplemented', unimplemented)

$(() => {
    App.Kernel.bindComponents($("body"));
});

import '@ungap/custom-elements'; // Polyfill for custom build-ins elements

import ChatBot from "./js/components/ChatBot/ChatBot";
import ClientsCarousel from "./js/components/ClientsCarousel";
import CustomHeader from "./js/components/CustomHeader";
import ListeVehicules from "./js/pages/ListeVehicules";
import ListeActualites from "./js/pages/ListeActualites";

document.addEventListener('DOMContentLoaded', () => {
    customElements.define("chat-bot", ChatBot, { extends: "div" });
    customElements.define("clients-carousel", ClientsCarousel, { extends: "div" });
    customElements.define("liste-actualites", ListeActualites, { extends: "div" });
    customElements.define("liste-vehicules", ListeVehicules, { extends: "div" });
    customElements.define("custom-header", CustomHeader, { extends: "header" });
})



import './scss/main.scss';